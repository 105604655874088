import api from "./config.js";
import axios from 'axios';

let server = axios.create({
    baseURL: api,
    timeout: 10000
})

server.interceptors.request.use(config => {
    // config.headers['CLIENT'] = '2000';

    return config
}, error => {
    Promise.reject(error)
})


server.interceptors.response.use(res => {
    return res.data || {}
})


export default server